.container {
    display: flex;
    flex-direction: column;
    min-height: 80vh;
    width: 100%;
    padding-top: 140px;
    padding-bottom: 120px;
}

@media (max-width: 900px) {
    .container {
        text-align: center;
    }
}
