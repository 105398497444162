.tabsHeader {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.tabsContent {
    margin-top: 70px;
}

.tabContent {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns with equal width */
    gap: 20px; /* Spacing between grid items */
}
