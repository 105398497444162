.leaderBoardSection {
    background-color: var(--bgDark);
    padding-top: 120px;
}


.mapsButton {
    display: flex;
    justify-content: center;
}


.headingText {
    text-align: center;
}

.buttonContainer {
    display: flex;
    margin-top: 30px;
    justify-content: center;
}

.monsterContainer {
    margin-top: 130px;
    margin-bottom: 120px;
}

.monsterContentContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-height: 280px;
}

.btnPadding {
    padding-right: 30px;
    padding-left: 30px;
}


.leaderboardPlaceholder{
    height: 30vh;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

}
