.container {
    @media (max-width: 900px) {
        margin-top: 120px;
    }
}


.contentContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 44px 32px 64px;
    max-width: 50%;
    min-height: 280px;
    margin-left: auto;
    text-align: start;
    @media (max-width: 900px) {
        max-width: unset;
    }
}


.monsterContainer {
    position: absolute;
    top: -15px;
    left: -32px;
    z-index: 3;

    @media (max-width: 900px) {
   display: none;
    }
}

