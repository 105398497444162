.playerSection {
    margin-top: 180px;
}

.playersContainer {
    margin: 80px 10px 0;
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns with equal width */
    gap: 10px; /* Spacing between grid items */
}

.smallPlayersContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 80px 10px 0;
    gap: 10px; /* Spacing between grid items */
}