

.titleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
}

.locationContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.offlineTitle {
    margin: 10px 0;
}

.offlineSubtitle {
    font-weight: 600;
    margin: 0;
}

.gradientBackground {
    width: 100%;
    background: var(--gradientSecondary);
}

.entryContainer {
    text-align: left;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.entryVerticalContainer {
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.entryRow {
    display: flex;
    gap: 5px;
}

.timetableTime {
    font-size: 20px;
    font-weight: 600;
    width: 60px;
}

.timetableEvent {
    font-size: 20px;
    font-weight: 400;
}
