
.playerCardContainer {
    box-sizing: border-box;
    width: 100%;

}

.cardBackContent,
.cardFrontContent {
    width: 100%;
    top: 0;
    left: 0;
    transition: transform 0.3s ease-in-out;

    background-color: var(--bg);
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cardSmallContent {
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding: 20px;
}

.avatarContainer {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 3;
}

.avatar {
    padding: 40px;
    max-width: 220px; /* Beispielgröße, anpassen wie benötigt */
    width: 30%;
    aspect-ratio: 1/1;
    border-radius: 50%; /* Macht den Avatar rund */
    object-fit: cover; /* Behält das Seitenverhältnis des Bildes bei */
    z-index: 3; /* Stellt sicher, dass der Avatar über dem Gradienten liegt */
}

.cardBackContent {
    z-index: 3;
}

.active {
    transform: rotateY(0);
}

.playerTopControls {
}

.playerSocialsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.playerSocialsContainer img {
    margin-right: 12px;
}

.playerSocialsContainer img:hover {
    cursor: pointer;
}

.flipArrow {
    position: absolute;
    top: 7%;
    right: 5%;
}

.flipArrow:hover {
    cursor: pointer;
}

.playerInfoContainer {
    position: absolute;
    bottom: 7%;
    left: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.playerInfoContainer a {
    margin: auto 0;
}

.playerTag {
    margin: auto 0;
    font-size: 21px;
    font-weight: 700;
    padding-right: 12px;
}

.playerLink {
    z-index: 5;
}

.teamTag {
    font-size: 16px;
    font-weight: 700;
    color: #D9D9D9;
}



