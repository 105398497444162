.rbSlide {
    padding: 50px 80px;
    height: 100%;
    position: relative;
}


.formContent {
    width: 100%;
}


.rbForm {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
}

.rbForm input {
    margin-bottom: 15px;
}

.rbSubmitButtons {
    margin-top: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.checkboxText {
    font-weight: 100;
    font-size: 16px;
}

.checkboxLabel {
    margin-top: 15px;
}


.successMessage {
    margin-top: 15px;
    font-weight: bold;
    color: var(--primary);
}

.errorMessage {
    margin-top: 15px;
    font-weight: bold;
    color: var(--error);
}
