.avatar {
    border-radius: 50%;
    border: 1px solid #FFFFFF;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-shrink: 0;
}

.avatar img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.img1 {
    transform: translateX(-50%);
}

.img2 {
    transform: translateX(50%);
}


.imgTeam {

}
