.container {
    position: fixed;
    top: 0;
    left: 0;
    max-width: 100%;
    width: 100%;
    height: fit-content;
    z-index: 1000;
}

.navBarContainer {
    top: 1%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 20%;
    background: linear-gradient(0deg, rgba(77, 10, 59, 0) 0%, #4D0A3B 25%, #190918 100%);
    padding-bottom: 100px;
}

.fixedNavbarItemContainer {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    pointer-events: all;
    gap: 32px;
}

.fixedNavbarItem {
    position: relative;
    cursor: pointer;
    pointer-events: all;
}

.navbarLink {
    text-decoration: none;
    color: white;
    pointer-events: all;
}

.navbarLink.active {
    padding: 1px 5px;
    border: 1px solid #FFFFFF;
}