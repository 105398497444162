.rulesSlide {
    display: flex;
    flex-direction: column;
    padding: 20px 70px 40px 40px;
    background-color: var(--primary-dark);
    min-height: 250px;
    max-width: 620px;

    @media (max-width: 900px) {
        padding: 10px 0 10px 10px;
        margin-bottom: 10px;
    }
}


.contentSlide {
    display: flex;
    flex-direction: column;
    max-width: 620px;
}

.contentSlide h3 {
    color: #FFF;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px; /* 156.25% */
    text-transform: uppercase;
    margin: 0;
}
