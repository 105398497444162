.welcomeContainer {
    padding: 0 10px;
    min-height: 90vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(180deg, #190918 0%, #4D0A3B 100%);
    text-align: center;
}

.introductionPaper {
    margin-top: 70px;
}
