:root {
    --distance: 0px;
}
.partnerLogo {
    max-height: 60px;
    max-width: 180px;
    cursor: pointer;
}



.partnerContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    padding-top: var(--distance);
    padding-bottom: calc(var(--distance) * 0.58);
}

@media (max-width: 900px) {
    .partnerContainer {
      display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 120px;
        justify-items: center;
        align-items: center;
        padding-top: calc(var(--distance) * 0.8);
        padding-bottom: calc(var(--distance) * 1.2);
    }

    .smallLogo {
        height: 40px;
        max-width: 120px;
    }
}
