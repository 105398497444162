.container {
    background-color: var(--bgDark);
    padding-bottom: 170px;
    width: 100%;
    margin: 0 auto;
}

.rulesSection {
    padding: 0 10px;
}

.rulesTitle {

}

.rulesSubtitle {
    width: 75%;
    max-width: 600px;
}

.rulesContent {
    margin-top: 50px;
}