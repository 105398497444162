.nzSlide {
    padding: 50px 80px;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
}

.subtitle {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 20px;
}


.textContainer {
    width: 50%;
}


.nyfterLogo {
    position: absolute;
    top: 20%;
    right: 10%;
    overflow: visible;
}

.xperionLogo {
    position: absolute;
    height: 380px;
    bottom: 10%;
    right: 5%;
    overflow: visible;

}

.qrCode {
    position: absolute;
    height: 250px;
    width: 520px;
    top: 15%;
    right: 2%;
}


.nzButtons {
    margin-top: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

