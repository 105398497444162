.leaderboardRow {
    border-top: 0.5px solid #575757;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 9px;
    padding-bottom: 12px;
    max-width: 100%;
    margin: auto;
}


.rankText {
    flex: 0 0 5%;
}

.profileContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 0 0 45%;
}


.pointsContainer {
    display: flex;
    justify-content: right;
    flex: 0 0 15%;
}

.nameContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.nameContainer h5 {
    flex: 0 0 100%;
}


.pointsContainer > *:not(:last-child) {
    margin-right: 23px;
}







.emblemContainer {
    display: flex;
    justify-content: center;
}

.emblemContainer > *:not(:last-child) {
    margin-right: 12px;
}


