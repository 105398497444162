.welcomeContainer {
    padding: 40px 10px 40px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(180deg, #190918 0%, #4D0A3B 100%);
    text-align: center;
}

.introHeader {
    padding-bottom: 40px;
}

.introBody {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 20px;
}

.introductionPaper {
    margin-top: 70px;
}

.textBlock {
    margin-bottom: 24px;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-flow: row wrap;
    margin-top: 32px;
    z-index: 20;
}

.buttonContainer button:not(:last-child) {
    margin-right: 32px;
}